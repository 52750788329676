import {useState} from "react";
import {useTelegram} from "../../fns/useTelegram";


export function BidForm({orderInfo, closeDialog}) {

    const {initData} = useTelegram()
    const [calculated, setCalculated] = useState({ price: '', rpm: '' })
    const endPoint = 'https://platform.hfield.net/telegramwebapp/setbiddata'

    const sendDataToTelegram = async () => {
        let data = {...initData, ...calculated}
        const response = await fetch(endPoint, {
            method: "POST",
            mode: "cors",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(data)
        })
        return response.json()
    }

    const onChangePrice = (e) => {
        const price = e.target.value;

        if(!isNaN(price)) {
            const estimate_miles = orderInfo?.order_data?.estimate_miles
            if (estimate_miles) {
                const rpm = parseFloat(price / estimate_miles).toFixed(2)
                setCalculated({price, rpm})
            } else {
                setCalculated({price: '', rpm: ''})
            }
        }
    }

    const onChangeRpm = (e) => {
        const rpm = e.target.value;
        if (!isNaN(rpm)) {
            const estimate_miles = orderInfo?.order_data?.estimate_miles
            if (estimate_miles) {
                const price = parseFloat(rpm * estimate_miles).toFixed(0)
                setCalculated({price, rpm})
            } else {
                setCalculated({price: '', rpm: ''})
            }
        }
    }

    const handleFocus = (event) => {
        event.target.select()
    }

    return (
        <div className="progress-block-bottom">
            <div className="submit-price-block">

                <div className="submit-price-block-inputs">
                    <input
                        type="text"
                        placeholder="Total price"
                        /* value={calculated.price}*/
                        onInput={onChangePrice}
                        onFocus={handleFocus}
                        name="price"
                    />
                    OR
                    <input
                        type="text"
                        placeholder="RPM"
                        /* value={calculated.rpm}*/
                        onInput={onChangeRpm}
                        onFocus={handleFocus}
                        name="rpm"
                    />
                    <button id="bid-button" className="create-bid" onClick={sendDataToTelegram}>Make Offer</button>
                    <button id="return-button" className="create-bid" onClick={closeDialog}>Close</button>
                </div>
            </div>
        </div>
    )
}   