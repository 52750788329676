
import L from "leaflet"
import deliverySvg from "../assets/delivery.svg"
import pickupSvg from "../assets/pickup.svg"

export function addPickupDelivery(orderData, map) {

    const pIcon = L.icon({
        iconUrl: pickupSvg,
        iconSize: [38, 95],
        iconAnchor: [22, 55],
        popupAnchor: [-3, -25],
        shadowSize: [68, 55],
        shadowAnchor: [22, 55]
    });

    const dIcon = L.icon({
        iconUrl: deliverySvg,
        iconSize: [38, 95],
        iconAnchor: [22, 55],
        popupAnchor: [-3, -25],
        shadowSize: [68, 55],
        shadowAnchor: [22, 55]
    });

    if (orderData?.pickup?.loc) {
        L.marker(orderData?.pickup?.loc ? orderData?.pickup?.loc : [0, 0],
            {alt: orderData?.pickup?.address ? orderData.pickup.loc : '', icon: pIcon}).addTo(map)
            .bindPopup(orderData?.pickup?.address ? orderData.pickup.address : '');
    }

    if (orderData?.delivery?.loc) {
        L.marker(orderData?.delivery?.loc ? orderData.delivery.loc : [0, 0],
            {alt: orderData?.delivery?.address ? orderData.delivery.address : '', icon: dIcon}).addTo(map)
            .bindPopup(orderData?.delivery?.address ? orderData.delivery.address : '');
    }

    if (orderData?.truck?.loc && orderData?.pickup?.loc) {
        const bounds = L.latLngBounds(orderData?.truck?.loc ? orderData.truck.loc : [0, 0], orderData?.pickup?.loc ? orderData.pickup.loc : [0, 0]);
        map.flyToBounds(bounds, {duration: 0.1, padding: [10, 10]});
    }

    return map
}