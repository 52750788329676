
import {AppBar, Dialog, Slide, Toolbar} from "@mui/material";
import React, {useState} from "react";
import {BidForm} from "../BidForm/BidForm";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props}/>;
});

export function BidDialog({orderInfo}) {
    const [open, setOpen] = useState(false);
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <button id="bid-button" onClick={handleClickOpen}>Make Offer</button>
            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                TransitionComponent={Transition}
            >
                <AppBar sx={{ position: 'relative' }}>
                    <Toolbar></Toolbar>
                </AppBar>
                <BidForm closeDialog={handleClose} orderInfo={orderInfo}></BidForm>
            </Dialog>
        </React.Fragment>
    );
}