import DetailItem from "./DetailItem"

export function DetailsPage({orderInfo}) {

    let pageData = orderInfo?.order_data;

    return (

        <div className="progress-block-top">
            <div className="progress-block-info" id="details">
                <div className="progress-block-info-lineblock">
                    <DetailItem  label="Pick-up at:" value={pageData?.pickup_loc} />
                    <DetailItem  label="Pick-up date (EST):" value={pageData?.pickup_date} />
                </div>
                <div className="progress-block-info-lineblock">
                    <DetailItem  label="Deliver to:" value={pageData?.delivery_location} />
                    <DetailItem  label="Deliver date (EST):" value={pageData?.delivery_date} />
                </div>
                <div className="progress-block-info-lineblock">
                    <DetailItem  label="Estimated Miles:" value={pageData?.estimate_miles} />
                    <DetailItem  label="Out Miles:" value={pageData?.out_miles} />
                </div>
                <div className="progress-block-info-lineblock">
                    <DetailItem  label="Pieces:" value={pageData?.pieces} />
                    <DetailItem  label="Weight:" value={pageData?.weight} />
                    <DetailItem  label="Stackable:" value={pageData?.stackable} />
                    <DetailItem  label="Suggested Truck Size:" value={pageData?.truck_size} />
                </div>
                <div className="progress-block-info-lineblock">
                    <DetailItem  label="Driver:" value={pageData?.driver_fullname} />
                    <DetailItem  label="Vin:" value={pageData?.vin_num} />
                    <DetailItem  label="Plate Number:" value={pageData?.plate_num} />
                </div>
                <div className="progress-block-info-lineblock">
                    <div className="progress-block-info-line">
                        <div className="pb-notes">
                            <div className="pb-notes-title">Notes</div>
                            <div className="pb-notes-content">{ pageData?.note }</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
