import {useEffect} from "react";
import {useTelegram} from "./fns/useTelegram";
import {AppWrapper} from "./Components/AppWrapper"

function App() {
  const {expand} = useTelegram();
  //console.log(tg.isExpanded)

    /*useEffect(() => {
        tg.onEvent('viewportChanged', (isStateStable) => {
            console.log(isStateStable)
            tg.expand()
        })

        return () => {
            tg.offEvent('viewportChanged', (isStateStable) => {  tg.expand() })
        }
    }, [])*/

    useEffect(() => {
        expand()
    }, [])

    //setTimeout(() => expand(), 500)

  return (
    <div className="App">
      <div className="wrapper">
          <div className="progress-blocks">
            <div className="progress-block">
              <AppWrapper />
            </div>
          </div>
        </div>
    </div>
  );
}

export default App;
