
import L from "leaflet"
import vehicle from "../assets/tr.png"

export function getVehicleMarker(orderData, map) {

        const title = orderData?.truck?.identifier ? orderData.truck.identifier : '';
        const truckDegrees = orderData?.truck?.path?.bearing ? orderData.truck.path.bearing : '';
        const name = orderData?.truck?.name ? orderData.truck.name : ''
        const address = orderData?.truck?.address ? orderData.truck.address : ''
        const distance = orderData?.truck?.path?.distance ? orderData.truck.path.distance : ''
        const total = orderData?.route?.distance ? orderData.route.distance : ''

        const iconOptions = {
            iconUrl: vehicle,
            /*iconSize: [63, 185],*/
            iconSize: [33, 120],
            iconAnchor: [31, 92],
            popupAnchor: [0, -25]
        };
        const customIcon = L.icon(iconOptions);

        const markerOptions = {
            icon: customIcon,
            title: title,
            draggable: false,
            rotationAngle: truckDegrees,
        };

        let trInfo = `<b>${title}</b><br>Driver: ${name}<br>${address}<br>Out Miles; ${distance}<br>Total Miles: ${total}`;

        const truck = new L.marker(
            orderData?.truck?.loc ? orderData.truck.loc : [0,0],
            markerOptions
        ).addTo(map);

        truck.bindPopup(trInfo)

        return map

}