import LoadDetails from "../../assets/load-details.svg"
import LiveMap from "../../assets/live-map.svg"
import {useState} from "react";

export function InfoMapSwitcher({switchViews}) {

    const [switcher, setSwitcher] = useState({ info: false, map: true })

    const infoButtonClick = () => {
        setSwitcher({info: true, map: false})
        switchViews({info: true, map: false})
    }

    const mapButtonClick = () => {
        setSwitcher({info: false, map: true})
        switchViews({info: false, map: true})
    }

    return (
        <div className="info-map-switcher">
            <div className={`info-map-switcher-var ${switcher.map ? "active" : ""} `} onClick={ mapButtonClick  }><img src={LiveMap} alt="Live Map" />Live Map</div>
            <div className={`info-map-switcher-var ${switcher.info ? "active" : ""}`} onClick={ infoButtonClick } ><img src={LoadDetails} alt="Load Details"/>Load Details</div>
        </div>
    )
}