import {useEffect, useState} from "react";
import {InfoMapSwitcher} from "./InfoMapSwitcher/InfoMapSwitcher";
import {DetailsPage} from "./DetailsPage/DetailsPage";
import {LiveMapContainer} from "./LiveMap/LiveMapContainer";
import {useTelegram} from "../fns/useTelegram";
import {BidDialog} from "./BidDialog/BidDialog";

export function AppWrapper() {

    const [panels, setPanels] = useState({info: false, map: true})
    const [bidData, setBidData] = useState(undefined)
    const {tg} = useTelegram()

    const switchViews = (data) => {
        setPanels(data)
    }

    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let order_id = urlParams.get('tgWebAppStartParam');

    useEffect( () => {
        let ignore = false;

        const bidData = async () => {
            const data = await fetch('https://platform.hfield.net/telegramwebapp/getbiddata', {
                    method: 'POST',
                    mode: "cors",
                    headers: {"Content-Type": "application/json",
                },
                body: JSON.stringify({order_id, telegram_id: tg?.initDataUnsafe?.user?.id}),
            })

            return await data.json();
        }

        bidData().then((res) => {
            if (!ignore) {
                console.log(res);
                setBidData(res)
            }
        }).catch((res) => {
            alert("Service Temporarily Unavailable")
            alert(JSON.stringify(res))
        })
        return () => {
            ignore = true;
        };
    }, [order_id]);

    return (
        <div>
            <InfoMapSwitcher switchViews={switchViews}/>
            {panels.info && <DetailsPage orderInfo={bidData}/>}
            {panels.map && <LiveMapContainer orderInfo={bidData}/>}
            <BidDialog orderInfo={bidData}/>
        </div>
    );
}
