import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

export default function DetailItem({label, value}) {
    return (
        <div className="progress-block-info-line">
            <div className="pb-info-line-title">{ label }</div>
            <div className="pb-info-line-value">{ value || <Skeleton width={150}/> }</div>
        </div>
    )
}