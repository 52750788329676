const tg = window.Telegram.WebApp;
const tgWebProxy = window.TelegramWebviewProxy;

export function useTelegram() {

    const onClose = () => {
        tg.close()
    }

    const onToggleButton = () => {
        if (tg.MainButton.isVisible) {
            tg.MainButton.hide()
        } else {
            tg.MainButton.show();
        }
    }

    const showMainButton = () => {
        tg.MainButton.enable()
        tg.MainButton.show()
    }

    const expand = () => {
        if (!tg.isExpanded) {
            tg.expand();
        }
    }



    return {
        tg,
        tgWebProxy,
        expand,
        user: tg.initDataUnsafe?.user,
        queryId: tg.initDataUnsafe?.query_id,
        initData: tg.initDataUnsafe,
        onClose,
        onToggleButton,
        showMainButton
    }
}