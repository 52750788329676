
import L from "leaflet"
import "../../fns/L.Path.DashFlow"
import "leaflet-rotatedmarker"
import {useEffect, useRef} from "react";
import {getVehicleMarker} from "../../fns/vehicleMarker";
import {addRoute} from "../../fns/addRoute";
import {addPickupDelivery} from "../../fns/addPickupDelivery";

export function LiveMapContainer({orderInfo}) {
    const mapContainer = useRef();

    useEffect(() => {
        let map = L.map(mapContainer.current, {attributionControl: false}).setView([40.73307350435217, -74.00047795614519], 5);

        L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
            attribution: '&copy; <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        }).addTo(map);

        getVehicleMarker(orderInfo, map);

        addRoute(orderInfo, map)

        addPickupDelivery(orderInfo, map)

        if (orderInfo?.truck && orderInfo?.pickup && orderInfo?.delivery) {
            map.fitBounds([
                orderInfo.truck.loc,
                orderInfo.pickup.loc,
                orderInfo.delivery.loc
            ]);
        }

        return () => map.remove();
    }, [orderInfo]);

    return (
        <div>
            <div className="view-map"
                 ref={el => mapContainer.current = el}>
            </div>
            {/* <div style={{padding: 0, margin: 0, width: "100%", height: "75vh",}}
                 ref={el => mapContainer.current = el}>
            </div>*/}
        </div>
    );
}